
import NoDataStub from '@/components/common/NoDataStub.vue';

export default {
  name: 'RequestHasSent',
  components: { NoDataStub },
  inject: ['RouteNames'],
  methods: {
    onButtonAction() {
      this.$router.push({
        name: this.RouteNames.R_APP_ASK_FOR_ASSESSMENT,
        params: { surveyId: this.$route.params.surveyId },
      });
    },
  },
};
